import React, { useEffect,useContext }from 'react';

import '../styles/login.screen.style.scss';

/**Imortation des helpers */

import { ContextP } from "../context/provider";

function LoginView( props ) {

    const {LOGIN} = useContext(ContextP);
    // const {authREGISTRATION} = useContext(ContextP);

    function login() {
        
        // let user = {
        //     email : document.forms["login"]["userName"].value,
        //     password : document.forms["login"]["password"].value,
        // };

        let user = new FormData();
        user.append('email', document.forms["login"]["userName"].value);
        user.append('password', document.forms["login"]["password"].value);

        LOGIN(user, (res)=>{
            localStorage.setItem("aiiclassrom", JSON.stringify(res));
            window.location.href = "/dashboard/admin";
                    
        }, (e)=>{
            if(e.response) {
                if(e.response.data) {
                    if(e.response.data.message) {
                        alert(e.response.data.message);
                    }
                }
            }
            
        });
    }
    // useEffect(() => {

    //     let user = new FormData();
    //     user.append('email', 'admin@gmail.com');
    //     user.append('password', 'Admin3d48$');
    //     user.append('first_name', 'admin'); 
    //     user.append('last_name', 'admin'); 
    //     user.append('phone', '+22900000000'); 
        
    //     authREGISTRATION(user, (res)=>{
    //         // localStorage.setItem("ilera", JSON.stringify(user.user_info));
    //         let user = res;
    //         console.log(res);
    //         // window.location.href = "/dashboard/admin";
                
                    
    //     }, (e)=>{
    //         console.log(e);
    //         if(e.response) {
    //             if(e.response.data) {
    //                 if(e.response.data.message) {
    //                     alert(e.response.data.message);
    //                 }
    //             }
    //         }
            
    //     });
    // },[]);

    return (
        <div className='login'>
            <div className='login_content'>
                <div className='login_content_head'>
                    <div className='login_content_head_logo'>
                        <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1920 1920">
                            <path d="M1581.294 1694.118c0 62.23-50.71 112.94-112.941 112.94H451.883c-62.231 0-112.942-50.71-112.942-112.94V1016.47h1242.353v677.647ZM564.824 903.529V508.235c0-217.976 177.317-395.294 395.294-395.294 217.976 0 395.294 177.318 395.294 395.294h112.94C1468.353 228.028 1240.326 0 960.119 0S451.882 228.028 451.882 508.235V903.53H226v790.589C226 1818.692 327.308 1920 451.882 1920h1016.47c124.575 0 225.883-101.308 225.883-225.882V903.529H564.824Zm338.823 677.647h112.941v-338.823h-112.94v338.823Z" fill-rule="evenodd"/>
                        </svg>
                    </div>
                    <div className='login_content_head_title'>
                        <div className='login_content_head_title_top'>
                            Se connecter
                        </div>
                        <div className='login_content_head_title_bottom'>
                            <span>Veuillez saisir vos identifiants pour vous connecter</span>
                        </div>
                    </div>
                </div>

                <form name="login" action='#' method='POST' className='login_content_form'
                    onSubmit={(e) => {
                        e.preventDefault();
                        login();
                    }}
                >
                    <div className='login_content_form_input'>
                        <div className='login_content_form_input_title'>
                            <span>Email </span>
                            <span className='fa fa-envelope'></span>
                        </div>
                        <div className='login_content_form_input_balise'>
                            <input name="userName" required placeholder="" type='text'/>
                        </div>
                    </div>

                    <div className='login_content_form_input'>
                        <div className='login_content_form_input_title'>
                            <span>Mot de passe </span>
                            <span className='fa fa-lock'></span>
                        </div>
                        <div className='login_content_form_input_balise'>
                            <input name="password" required placeholder='***********' type='password'/>
                        </div>
                    </div>

                    <div className='login_content_form_button'>
                        <button>
                            Se connecter
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}


export default LoginView;