import * as React from "react";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

/**Importation des vues */
import Home from "../screens/home.screen";
import LoginView from '../screens/login.screen';
import RegistrationView from '../screens/registration.screen';
// import PriceView from "../screens/priceView.screen";
import RewardsPage from "../screens/rewardsView.screen";

// import PaymentView from "../screens/payment.screen";
import ProgramView from "../screens/programView.screen";
import FakePage from "../screens/fake.screen";
import DashboardAdminRegistrations from "../screens/dashboard.admin.registrations.screen";
import DashboardAdminTheme from "../screens/dashboard.admin.theme.screen";
import DashboardAdmin from "../screens/dashboard.admin.screen";

import ErrorPage from "../screens/error.screen";


const router_ = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
        children: [
            {
                path: "/registration",
                element: <RegistrationView/>,
                errorElement: <ErrorPage/>,
            },
            {
                path: "/program",
                element: <ProgramView/>,
                errorElement: <ErrorPage/>,
            },{
                path: "/rewards",
                element: <RewardsPage/>,
                errorElement: <ErrorPage/>,
            }
        ],
        errorElement: <ErrorPage/>,
    },{
        path: "login",
        element: <LoginView/>,
        errorElement: <ErrorPage/>,
    }
    
    ,{
        path: "/dashboard/admin/",
        element: <DashboardAdmin/>,
        children: [{
            path: "registrations",
            element: <DashboardAdminRegistrations/>
        },{
            path: "theme",
            element: <DashboardAdminTheme/>
        }],
        errorElement: <ErrorPage/>,
    }
]);

const router__ = createBrowserRouter([
    {
        path: "/",
        element: <FakePage/>,
        children: [],
        errorElement: <ErrorPage/>,
    }
]);

const TEST = false;

function Route() {
    if(TEST) {
        return (
            <RouterProvider router={router__}/>
        );
    } else {
        return (
            <RouterProvider router={router_}/>
        );
    }
}
  
export default Route;