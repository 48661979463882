import React, {useEffect} from 'react';
// import { ContextP } from "../context/provider";

import '../styles/homeView.screen.style.scss';

/**Importation des images */
import teamImg from '../assets/gif/system-regular-23-calendar-hover-calendar.gif';
import teamGif from '../assets/gif/system-regular-76-newspaper-hover-newspaper.gif';
import IAGif from '../assets/gif/system-regular-134-celebration-hover-celebration.gif';
import background from '../assets/images/Background.jpg';
import illustrator from '../assets/gif/system-regular-45-category-hover-category.gif';

import background_video from '../assets/videos/183107-870151708_small.mp4';
// import programIllustrator from '../assets/images/groupe-etude-du-peuple-africain.jpg';

import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Typography from '@mui/joy/Typography';
import Check from '@mui/icons-material/Check';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import CardOverflow from '@mui/joy/CardOverflow';
import CardContent from '@mui/joy/CardContent';
import SvgIcon from '@mui/joy/SvgIcon';
import IconButton from '@mui/joy/IconButton';
import Avatar from '@mui/joy/Avatar';
import ButtonGroup from '@mui/joy/ButtonGroup';

import mb9 from '../assets/images/blank-profile.png';
import houenagnon from '../assets/images/Formateur/Houénagnon DJOSSOU.png';
import moren from '../assets/images/Formateur/Mauren ADJIGNON.jpg';
import francois from '../assets/images/Formateur/François AMOUSSOU.jpg';
import rene from '../assets/images/Formateur/MIDOKPE Réné.jpg';
import marion from '../assets/images/Formateur/Marion de SOUZA.jpg';
import francis from '../assets/images/Formateur/Francis DOGBLOUE.jpg';
import chekpo from '../assets/images/Formateur/WhatsApp Image 2024-09-05 à 14.03.56_5cdd5ea9.jpg';

import TeamCard from '../components/teamCard/teamCard.component';

import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr');

const localizer = momentLocalizer(moment);

function HomeView( props ) {
    // const {isLoading, setIsLoading} = useContext(ContextP);
    const program = [
        {
            id: 1,
            title: <h2 className='calendar_event_title'>Début du Classroom <span className="fa fa-hourglass-start"></span></h2>,
            start: new Date(2024, 8, 16),
            end: new Date(2024, 8, 16),
            color: '#ffccff'
        },
        {
            id: 2,
            title: <h2 className='calendar_event_title'>Cours intensifs <span className="fa fa-book"></span></h2>,
            start: new Date(2024, 8, 16),
            end: new Date(2024, 8, 20),
        },
        {
            id: 3,
            title: <h2 className='calendar_event_title'>Distribution des briefs <span className="fa fa-tag"></span></h2>,
            start: new Date(2024, 8, 21),
            end: new Date(2024, 8, 21),
        },
        {
            id: 4,
            title: <h2 className='calendar_event_title'>Travail sur les projets et création de contenu <span className="fa fa-tag"></span></h2>,
            start: new Date(2024, 8, 22),
            end: new Date(2024, 8, 27),
        },
        {
            id: 5,
            title: <h2 className='calendar_event_title'>Dépôt des travaux <span className="fa fa-tag"></span></h2>,
            start: new Date(2024, 8, 27),
            end: new Date(2024, 8, 27),
        },
        {
            id: 5,
            title: <h2 className='calendar_event_title'>Annonce des finalistes <span className="fa fa-tag"></span></h2>,
            start: new Date(2024, 8, 30),
            end: new Date(2024, 8, 30),
        },
    ]
    const team = [
        {
            "id": 1,
            "name": "François Xavier AMOUSSOU",
            "position": "",
            "description": "Montage vidéo assisté par l’IA",
            "image": francois,
            "socialLinks": []
        },{
            "id": 1,
            "name": "Mauren ADJIGNON",
            "position": "",
            "description": "Retouche photo créative assistée par l’IA",
            "image": moren,
            "socialLinks": []
        },{
            "id": 1,
            "name": "Houenagnon DJOSSOU",
            "position": "",
            "description": "Gestion et conception de campagnes publicitaires assistées par l’IA",
            "image": houenagnon,
            "socialLinks": []
        },{
            "id": 1,
            "name": "MIDOKPE Réné",
            "position": "",
            "description": "Création de contenus assistée par l’IA",
            "image": rene,
            "socialLinks": []
        },{
            "id": 1,
            "name": "CHEKPO N. Ousman",
            "position": "",
            "description": "Création de bot Messenger alimenté par l’IA",
            "image": chekpo,
            "socialLinks": []
        },{
            "id": 1,
            "name": "Marion Obed de SOUZA",
            "position": "",
            "description": "Prompt Engineering & comment monter un business grâce à l’IA",
            "image": marion,
            "socialLinks": []
        }
        // ,{
        //     "id": 1,
        //     "name": "CHEKPO N. Ousman",
        //     "position": "",
        //     "description": "Création de comptes monétisés TikTok et YouTube",
        //     "image": mb9,
        //     "socialLinks": []
        // }
        ,{
            "id": 1,
            "name": "Françis DOGBLOUE",
            "position": "",
            "description": "Conception de Miniateure YouTube assité par IA",
            "image": francis,
            "socialLinks": []
        }
    ];

    const messages = {
        today: 'Aujourd\'hui',
        previous: 'Précédent',
        next: 'Suivant',
        month: 'Mois',
        week: 'Semaine',
        day: 'Jour',
        agenda: 'Agenda',
        date: 'Date',
        time: 'Heure',
        event: 'Événement',
        allDay: 'Toute la journée',
        noEventsInRange: 'Aucun événement dans cette plage de dates.',
    };

    
    // Fonction pour définir les styles personnalisés des événements
    const eventPropGetter = (event, start, end, isSelected) => {
        const style = {
        backgroundColor: event.color,
        borderRadius: '5px',
        opacity: 0.8,
        color: 'black',
        border: '0px',
        display: 'block',
        };
        return { style: style };
    };

    useEffect(() => {
        // let vid = document.getElementById("myVideo");
        // vid.playbackRate -= 0.5;
    });
    return (
        <div className='homeViewContent'>
            <section className='section_sc'>
               
                <div className="container">
                    <div className="overlay">
                        {/* <img src="school-background.jpg" alt="Background Image" className="background-image"> */}
                        <div className="content">
                            <h2 className="content_left">
                                Bienvenue à l'AI Innovators Classroom!
                            </h2>
                            <div className="content_right">
                                Transformez vos compétences en Design graphique, publicité avec l'IA et <br/>devenez un innovateur de demain.
                            </div>
                        </div>
                        <div className='illustrator-te'>
                            <img src={illustrator} alt=''/>
                        </div>
                    </div>
                </div>
            </section>

            <section className='section section_program'>
                <h3 className='h3'>
                    <a href='/registration' style={{color: "#ffffff"}}>Rejoignez-nous <span className='fa fa-check'></span></a>
                </h3>
                <div className='section_program_head'>
                    {/* <div className='section_program_head_left'>
                        <img src={programIllustrator} alt=''/>
                    </div> */}
                    <div className='section_program_head_title'>
                        
                        <h4>
                            Vous cherchez le meilleur programme d'études selon vos intérêts?
                        </h4>
                        <p>
                            Rejoignez-nous dès septembre pour un mois d’aventure en gestion et conception de campagnes publicitaires, retouche photo avec IA, prompt ingénierie, et bien plus encore. Des experts du domaine, vous guideront à travers des sessions pratiques et des challenges captivants.
                        </p>
                    </div>
                </div>

                <div className='section_program_list'>
                    <div className='section_program_list_illustrator'>
                        <img className='team' src={teamImg} alt='AI'/>
                        <img className='teamGif' src={teamGif} alt='AI'/>
                        <img className='IAGif' src={IAGif} alt='AI'/>
                    </div>
                    <div className='section_program_list_box'>
                        <Box
                    
                            sx={{
                            
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fill, minmax(min(100%, 100%), 1fr))',
                                gap: 1,
                            }}
                            
                        >
                            <Card size="lg" variant="outlined">
                                <Typography level="h2">Pourquoi nous rejoindre?</Typography>
                                <Divider inset="none" />
                                <Typography level="h5" textColor='#000000' fontSize={"1em"} fontWeight={"bold"}>Formation quotidienne:</Typography>
                                {/**--------------------------------------------------------- */}
                                <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
                                    <img className='team' src={teamImg} alt='AI'/>
                                    <ListItem>
                                        <ListItemDecorator>
                                            <Check />
                                        </ListItemDecorator>
                                        
                                        Apprenez chaque jour avec des spécialistes.
                                    </ListItem>
                                </List>
                                
                                <Typography level="h5" textColor='#000000' fontSize={"1em"} fontWeight={"bold"}>Challenge publicitaire:</Typography>
                                {/**--------------------------------------------------------- */}
                                <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
                                    <img className='teamGif' src={teamGif} alt='AI'/>
                                    <ListItem>
                                        <ListItemDecorator>
                                            <Check />
                                        </ListItemDecorator>
                                        Mettez vos compétences à l'épreuve et participez à une compétition passionnante.
                                    </ListItem>
                                </List>

                                <Typography level="h5" textColor='#000000' fontSize={"1em"} fontWeight={"bold"}>Récompenses attractives:</Typography>
                                {/**--------------------------------------------------------- */}
                                <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
                                    <img className='IAGif' src={IAGif} alt='AI'/>
                                    <ListItem>
                                        <ListItemDecorator>
                                            <Check />
                                        </ListItemDecorator>
                                        Gagnez des prix exceptionnels, y compris des réductions sur des vacances créatives et des primes en espèces.
                                    </ListItem>
                                </List>
                            </Card>
                        </Box>
                    </div>
                    
                </div>
            </section>

            <section className='section section_program'>
                <h3 className='h3'>
                    Nos formateurs <span className='fa fa-check'></span>
                </h3>
                <div className='section_program_head'>
                    {/* <div className='section_program_head_left'>
                        <img src={programIllustrator} alt=''/>
                    </div> */}
                    <div className='section_program_head_title'>
                        
                        <h4>
                            Découvrez les experts qui vous guideront tout au long de ce <br/>programme innovant.
                        </h4>
                        
                    </div>
                </div>

                <div className='section_teacher_list'>
                    {
                        team.map((tm, index) => (
                            <div className='section_teacher_list_elt'>
                                <TeamCard mb={tm} />
                            </div>
                        ))
                    }
                </div>
            </section>

            <section className='section section_program'>
                <h3 className='h3'>
                    Notre programme <span className='fa fa-check'></span>
                </h3>

                <div className='' style={{maxWidth: "1250px", margin: "auto"}}>
                    <section className='programSection'>
                        <Calendar
                            localizer={localizer}
                            events={program}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ minHeight: '500px' }}
                            defaultView={Views.AGENDA}
                            messages={messages}
                            // dayPropGetter={dayPropGetter}
                            // showMultiDayTimes
                        />
                    </section>
                </div>
            </section>
        </div>
    );
}

export default HomeView;