import axios from "axios";
import PATH_ from '../GLOBAL-VAR/globals-urls';

const PATH__ = new PATH_();

class AuthRequest {
    login(data) {
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "?demande=login",
                headers: {
                    'Content-Type': 'multipart/form-data',
                    // 'Content-Type': 'application/json',
                },
            })
        )
    }

    registration(data) {
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "?demande=users/create",
                headers: {
                    'Content-Type': 'multipart/form-data',
                    // 'Content-Type': 'application/json',
                },
            })
        )
    }
}

export default AuthRequest;