import './App.css';

import Route from './route/route';

/**Importation des plugins */
import './plugin/font-awesome-4.7.0/css/font-awesome.min.css';

/**importation des styles globals */
import './styles/global/default.scss';
import './styles/fonts/font-style/font.css';
import './styles/global/animation.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import '@fontsource/inter';


function App() {
  return (
    <div className="App">
      <Route/>
    </div>
  );
}

export default App;
