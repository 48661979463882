import React, {useState, useContext, useEffect} from 'react';
import {
    useParams
} from "react-router-dom";
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

import Box from "@mui/material/Box";
import { Pagination } from "@mui/material";

import '../styles/dashboard.faq.style.scss';

import { ContextP } from "../context/provider";

import PATH_ from '../GLOBAL-VAR/globals-urls';

function DashboardAdminTheme( props ) {

    const {addTHEME} = useContext(ContextP);
    const {updateTHEME} = useContext(ContextP);
    
    const {getTHEMES} = useContext(ContextP);
    const {DeleteTHEME} = useContext(ContextP);
    const {addTHEMEImage} = useContext(ContextP);
    const {DeleteTHEMEImage} = useContext(ContextP);
    


    const [ARTICLE, setARTICLE] = useState(null);

    const [actionType, setActionType] = useState("add");
    const [currentARTICLE, setCurrentARTICLE] = useState(null);

    const path_ = new PATH_();

    function _add_() {

        let data = new FormData();
            
        data.append("name", currentARTICLE.name);
        data.append("description", currentARTICLE.description);
        // data.append("image_couverture", document.forms["actualityForm"]["file"].files);

        addTHEME(data, (res)=>{
            setCurrentARTICLE(null);
            _get_();
        }, (e)=>{
            console.log(e);
        });
            
    }

    function _update_() {
        if(window.confirm("Voulez-vous vraiment modifier ce theme ?")) {
            let data_ = {
                name: currentARTICLE.name,
                description: currentARTICLE.description
            };
            updateTHEME( currentARTICLE.id, data_, (res)=>{
                if(document.forms["actualityUpdateForm"]["file"].files.length>0) {
                    let data = new FormData();
                
                    data.append("theme_id", currentARTICLE.id);
                    data.append("image", document.forms["actualityUpdateForm"]["file"].files[0]);
                    // data.append("image_couverture", document.forms["actualityForm"]["file"].files);
            
                    addTHEMEImage(data, (res)=>{
                        setCurrentARTICLE(null);
                        _get_();
                    }, (e)=>{
                        console.log(e);
                    });
                } else {
                    setCurrentARTICLE(null);
                    _get_();
                }
        
            }, (e)=>{
                console.log(e);
            });
        }
    }

    function _delete_(id) {
        if(window.confirm("Voulez-vous vraiment supprimer ce theme ?")) {
            DeleteTHEME(id, (res)=>{
                setCurrentARTICLE(null);
                _get_();
            }, (e)=>{
                console.log(e);
            });
        }
    }

    function _delete_image(id) {
        // console.log(id);
        if(window.confirm("Voulez-vous vraiment supprimer cette image ?")) {
            DeleteTHEMEImage(id, (res)=>{
                _get_();
            }, (e)=>{
                console.log(e);
            })
        }
    }

    function _get_() {
        getTHEMES((res)=>{
            console.log(res);
            setARTICLE(res);
        }, (e)=>{
            return false;
        });
    }

    // function _get_category(page) {
    //     getCATEGORY(20000,page,(res)=>{
    //         // console.log(res);
    //         setCATEGORY(res.data.data);
    //     }, (e)=>{
    //         return false;
    //     });
    // }

    useEffect(() => {
       _get_();
    }, [])

    return (
        <div className='faq'>
            <div className='faq_content'>
                <div className='faq_content_button'>
                    <button
                        onClick={(e)=>{
                            setActionType("add");
                            setCurrentARTICLE({
                                titre: "",
                                nom: "",
                                description: "",
                            });
                        }}
                    >AJOUTER</button>
                </div>
                <br/>
                <div>
                    <table className='table-classic'>
                        <thead>
                            <tr>
                                <th>NOM</th>
                               
                                <th>DESCRIPTION</th>

                                <th>IMAGES</th>
                                
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ARTICLE!==null &&
                                !ARTICLE.message &&
                                ARTICLE.map((article, index)=>(
                                    <tr style={{
                                        background: index%2===0 ? "#00000005" : "transparent",
                                    }}>
                                        
                                        <td><strong>{article.name}</strong></td>
                                        <td><div className='content' dangerouslySetInnerHTML={{__html: article.description}}></div></td>
                                        <td>
                                            <div className='dh-images'>
                                                {
                                                    article.images.map((image, index) =>(
                                                        <div className='dh-images-content'>
                                                            <img src={path_.api_root + image.link} alt=""/>
                                                            <button
                                                                onClick={(e)=>{
                                                                    _delete_image(image.id);
                                                                }}
                                                            >
                                                                <span className='fa fa-trash'></span>
                                                            </button>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            
                                            {/* <img src={path_.api_root+"fonctions/media/"+article.image_actualite + "/"} alt='OREDS'/> */}
                                        </td>
                                        <td>
                                            <button className='update'
                                                onClick={(e)=>{
                                                    setActionType("update");
                                                    setCurrentARTICLE(article);
                                                }}
                                            >
                                                Modifier
                                            </button>
                                            <br/>
                                            {/* <button className='view'
                                                onClick={(e)=>{
                                                    setActionType("add_image");
                                                    setCurrentARTICLE(article);
                                                }}
                                            >
                                                Ajouter une image
                                            </button>
                                            <br/> */}
                                            <button className='delete'
                                                onClick={(e)=>{
                                                    _delete_(article.id);
                                                }}
                                            >
                                                supprimer
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <br/>
                    <br/>

                    
                    
                </div>
            {
                currentARTICLE !== null  && actionType==="add"?
                    <section className='information' >
                        <div className='information_content' style={{background: "#ffffff"}}>
                            <div className='information_content_close'>
                                <button onClick={(e) => {
                                    setCurrentARTICLE(null);
                                }}>
                                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="information_content_head">
                                <div className="information_content_head_id"></div>
                            </div>

                            <form action='#' method='POST' className='form' name="actualityForm"
                                onSubmit={(e)=>{
                                    e.preventDefault();
                                    _add_();
                                }}
                            >
                                

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>NOM</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input value={currentARTICLE.name?currentARTICLE.name:""} name='name' required placeholder='...' type='text'
                                            onChange={(e)=>{
                                                setCurrentARTICLE((prev)=>{
                                                    return({
                                                        ...prev,
                                                        "name": e.target.value
                                                    });
                                                })
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>DESCRIPTION</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        {/* <ReactQuill theme="snow"  /> */}
                                        <textarea value={currentARTICLE.description?currentARTICLE.description:""}
                                            onChange={(e) => {
                                                setCurrentARTICLE((prev)=>{
                                                    return({
                                                        ...prev,
                                                        "description": e.target.value
                                                    });
                                                })
                                            }} 
                                        ></textarea>
                                    </div>
                                </div>

                                <div className='form_button'>
                                    <button>
                                        Ajouter
                                    </button>
                                </div>
                            </form>
                        </div>
                    </section>
                :
                    null
            }

            {
                currentARTICLE !== null && actionType==="update"?
                    <section className='information' >
                        <div className='information_content' style={{background: "#ffffff"}}>
                            <div className='information_content_close'>
                                <button onClick={(e) => {
                                    setCurrentARTICLE(null);
                                }}>
                                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="information_content_head">
                                <div className="information_content_head_id"></div>
                            </div>

                            <form action='#' method='POST' className='form' name="actualityUpdateForm"
                                onSubmit={(e)=>{
                                    e.preventDefault();
                                    _update_();
                                }}
                            >
                                

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>NOM</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input value={currentARTICLE.name?currentARTICLE.name:""} name='name' required placeholder='...' type='text'
                                            onChange={(e)=>{
                                                setCurrentARTICLE((prev)=>{
                                                    return({
                                                        ...prev,
                                                        "name": e.target.value
                                                    });
                                                })
                                            }}
                                        />
                                    </div>
                                    
                                </div>


                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>DESCRIPTION</span>
                                    </div>
                                    
                                    <div className='form_input_balise'>
                                        {/* <ReactQuill theme="snow"  /> */}
                                        <textarea value={currentARTICLE.description?currentARTICLE.description:""}
                                            onChange={(e) => {
                                                setCurrentARTICLE((prev)=>{
                                                    return({
                                                        ...prev,
                                                        "description": e.target.value
                                                    });
                                                })
                                            }} 
                                        ></textarea>
                                    </div>
                                </div>

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>IMAGES</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input name='file' type='file' accept='image/*'/>
                                    </div>
                                </div>

                                {/* <div className='form_input'>
                                    <div className='form_input_box'>
                                        <input id='actuUNE' name='une' type='checkbox'/>
                                        <label style={{color: "#5dae00", marginLeft: "5px"}} htmlFor='actuUNE'>Définir comme à la une</label>
                                    </div>
                                </div> */}

                                <div className='form_button'>
                                    <button>
                                        Modifier
                                    </button>
                                </div>
                            </form>
                        </div>
                    </section>
                :
                    null
            }
            </div>
        </div>
    );
}

export default DashboardAdminTheme;