import React, {useEffect} from 'react';

import img from '../assets/gif/Presentation.gif';

function FakePage() {
    useEffect(() => {
        // let vid = document.getElementById("myVideo");
        // vid.playbackRate -= 0.5;

        // const playVideo = () => {
        //     vid.play().catch((error) => {
        //       // Gestion de l'erreur si la lecture automatique échoue
        //       console.log("Autoplay failed, waiting for user interaction.");
        //     });
        //   };
      
        //   // Redémarrer la vidéo lorsque la lecture est terminée
        //   const handleVideoEnd = () => {
        //     vid.currentTime = 0;
        //     vid.play();
        //   };
      
        //   // Ajouter les écouteurs d'événements
        //   vid.addEventListener('ended', handleVideoEnd);
      
        //   // Démarrer la vidéo automatiquement
        //   playVideo();
      
        //   // Nettoyage des écouteurs d'événements lors du démontage du composant
        //   return () => {
        //     vid.removeEventListener('ended', handleVideoEnd);
        //   };
    });
    return (
        <div id="fake-page">
            {/* <video
                src={video}
                muted={true}
                loop={true}
                controls={false}
                autoPlay={true}
                id="myVideo"
            /> */}
            <img src={img} alt=""/>
        </div>
    );
}

export default FakePage;