import React, {useState, useContext, useEffect} from 'react';

import 'react-quill/dist/quill.snow.css';

import '../styles/dashboard.faq.style.scss';

import { ContextP } from "../context/provider";

function DashboardAdminRegistrations( props ) {

    const {getREGISTRATIONS} = useContext(ContextP);


    const [REGISTRATIONS, setREGISTRATIONS] = useState(null);

    function _get_() {
        getREGISTRATIONS((res)=>{
            console.log(res);
            setREGISTRATIONS(res);
        }, (e)=>{
            return false;
        });
    }

    useEffect(() => {
        _get_();
    }, [])

    return (
        <div className='faq'>
            <div className='faq_content'>
                <br/>
                <div>
                    <table className='table-classic'>
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Email</th>
                                <th>Montant</th>
                                <th>Numéro</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                REGISTRATIONS!==null &&
                                REGISTRATIONS.map((register, index)=>(
                                    <tr style={{
                                        background: index%2===0 ? "#00000005" : "transparent",
                                    }}>
                                        <td><strong>{register.first_name} {register.last_name}</strong></td>
                                        
                                        <td>{register.email}</td>
                                        <td>{register.bill} XOF</td>
                                        <td>{register.phone}</td>
                                        
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    
                </div>
            
            </div>
        </div>
    );
}

export default DashboardAdminRegistrations;