import React, {useEffect, useState, useContext,} from 'react';
// import { ContextP } from "../context/provider";

import '../styles/programView.screen.style.scss';

import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';

import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

/**Importation des images */
// import img1 from '../assets/images/Couture 2.jpg';
// import img2 from '../assets/images/Violence Enfant.jpg';
// import img22 from '../assets/images/Cigarette.jpg';
// import img3 from '../assets/images/Mode 1.jpg';
// import img4 from '../assets/images/Le Sport Basket.jpg';
// import img5 from '../assets/images/Soin de corps 2.jpg';
// import img6 from '../assets/images/Protection chien.jpg';
// import img7 from '../assets/images/Boisson 1.jpg';
// import img8 from '../assets/images/Voyage 1.jpg';
// import img9 from '../assets/images/Festival igname.jpg';

import { ContextP } from "../context/provider";

import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';

import PATH_ from '../GLOBAL-VAR/globals-urls';

const localizer = momentLocalizer(moment);



// import 'react-big-calendar/lib/sass/styles';
// import 'react-big-calendar/lib/addons/dragAndDrop/styles';

function srcset(image, width, height, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${width * cols}&h=${
        height * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
}


function ProgramView( props ) {
    const {getTHEMES} = useContext(ContextP);

    const [THEME, setTHEME] = useState([]);

    const [IMAGES, setIMAGES] = useState(null);
    // const [Current, setCurrent] = useState(null);

    const path_ = new PATH_();

    function _get_() {
        getTHEMES((res)=>{
            console.log(res);
            setTHEME(res);
        }, (e)=>{
            return false;
        });
    }
    // const {isLoading, setIsLoading} = useContext(ContextP);
    const program = [
        {
            id: 1,
            title: <h2 className='calendar_event_title'>Début du Classroom <span className="fa fa-hourglass-start"></span></h2>,
            start: new Date(2024, 8, 16, 0, 0, 0),
            end: new Date(2024, 8, 16,  1, 0, 0),
        },
        {
            id: 2,
            title: <h2 className='calendar_event_title'>Cours intensifs <span className="fa fa-book"></span></h2>,
            start: new Date(2024, 8, 16, 2, 0, 0),
            end: new Date(2024, 8, 20, 23, 0, 0),
        },
        {
            id: 3,
            title: <h2 className='calendar_event_title'>Distribution des briefs <span class="fa fa-tag"></span></h2>,
            start: new Date(2024, 8, 21),
            end: new Date(2024, 8, 21),
        },
        {
            id: 4,
            title: <h2 className='calendar_event_title'>Travail sur les projets et création de contenu <span class="fa fa-tag"></span></h2>,
            start: new Date(2024, 8, 22),
            end: new Date(2024, 8, 27),
        },
        {
            id: 5,
            title: <h2 className='calendar_event_title'>Dépôt des travaux <span class="fa fa-tag"></span></h2>,
            start: new Date(2024, 8, 27),
            end: new Date(2024, 8, 27),
        },
        {
            id: 5,
            title: <h2 className='calendar_event_title'>Annonce des finalistes <span class="fa fa-tag"></span></h2>,
            start: new Date(2024, 8, 30),
            end: new Date(2024, 8, 30),
        },
    ]

    // const theme = [
    //     {
    //         name: "La couture",
    //         img: img1
    //     },
    //     {
    //         name: "La mode",
    //         img: img3
    //     },
    //     {
    //         name: "La lutte contre les violences",
    //         img: img2
    //     },
    //     {
    //         name: "La lutte contre la cigarette",
    //         img: img22
    //     },
    //     {
    //         name: "Le sport",
    //         img: img4
    //     },
    //     {
    //         name: "La sortie d’un produit de soin du corps",
    //         img: img5
    //     },
    //     {
    //         name: "Protection des animaux",
    //         img: img6
    //     },
    //     {
    //         name: "Un voyage",
    //         img: img8
    //     },
    //     {
    //         name: "Une boisson énergisante",
    //         img: img7
    //     },
    //     {
    //         name: "Un festival culturel",
    //         img: img9
    //     }
    // ]

    useEffect(() => {
        _get_();
     }, [])
    
    return (
        <div className='programViewContent'>
            <section className='programSection'>
                <Calendar
                    localizer={localizer}
                    events={program}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ minHeight: '500px' }}
                    // showMultiDayTimes
                />
            </section>

            <section className='section section_program'>
                <h3 className='h3'>
                    Thèmes des campagnes<span className='fa fa-check'></span>
                </h3>
                <div className='section_program_head'>
                    {/* <div className='section_program_head_left'>
                        <img src={programIllustrator} alt=''/>
                    </div> */}
                    <div className='section_program_head_title'>
                        
                        <h4>
                            Préparez-vous pour un mois d'apprentissage intense et de création innovante !
                        </h4>
                        
                    </div>
                </div>

                <div className='section_teacher_list'>
                    
                        {
                            THEME.map((th, index) => (
                                <div className='section_teacher_list_elt section_th_elt'>
                                <Card sx={{ width: "100%", minWidth: "300px" }}
                                    color="primary"
                                    invertedColors
                                    orientation="vertical"
                                    size="md"
                                    variant="soft"
                                >
                                
                                    <AspectRatio minHeight="120px" maxHeight="200px">
                                        <img src={(th.images.length && th.images.length>0) ? path_.api_root + th.images[th.images.length-1].link : ""}
                                            loading="lazy"
                                            alt=""
                                        />
                                    </AspectRatio>
                                    <div>
                                        <Typography level="title-lg">{th.name}</Typography>
                                    </div>
                                    <CardContent orientation="horizontal">
                                        <a href='##' className='cardThButton'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setIMAGES(th);
                                            }}
                                        >Explorer</a>
                                    </CardContent>
                                </Card>
                                </div>
                            ))
                        }
                    
                </div>
            </section>

            {
                IMAGES !== null ?
                    <section className='programViewContent_images'>
                        <div className='programViewContent_images_content'>
                            <div className='programViewContent_images_content_close'>
                                <button
                                    onClick={(e) => {
                                        setIMAGES(null);
                                    }}
                                >
                                    <span className='fa fa-close'></span>
                                </button>
                            </div>

                            <div className='programViewContent_images_content_album'>
                                {/* {
                                    IMAGES.images.length &&
                                    IMAGES.images.map((img, index) => (
                                        <div  className='programViewContent_images_content_album_'>
                                            <img src={path_.api_root + img.link} alt=""/>
                                        </div>
                                    ))
                                } */}
                                <Box sx={{ width: "100%", height: "auto", overflowY: 'scroll' }}>
                                    <ImageList variant="masonry" cols={3} gap={8}>
                                        {
                                            IMAGES.images.length &&
                                            IMAGES.images.map((img, index) => (
                                                index < IMAGES.images.length-1 &&
                                                <ImageListItem key={path_.api_root + img.link}>
                                                    <img
                                                    srcSet={path_.api_root + img.link}
                                                    src={path_.api_root + img.link}
                                                    alt={IMAGES.name}
                                                    loading="lazy"
                                                    />
                                                    <ImageListItemBar position="below" title={IMAGES.name} />
                                                </ImageListItem>
                                            ))
                                        }
                                    </ImageList>
                                </Box>

                            </div>
                        </div>
                    </section>
                :
                    null
            }
            
        </div>
    );
}

export default ProgramView;