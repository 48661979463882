import React, {useEffect} from 'react';
// import { ContextP } from "../context/provider";

import '../styles/priceView.screen.style.scss';

/**Importation des images */

import img1 from '../assets/icons/ic1.png';
import img2 from '../assets/icons/ic2.jpg';
import img3 from '../assets/icons/ic3.jpg';

import { Container, Grid, Card, CardContent, CardMedia, Typography } from '@mui/material';

const rewards = [
    {
      title: '1er Prix',
      description: 'Un montant de 25K et le livre "Mémoire Fatale" de Marion de SOUZA',
      image: img2 
    },
    {
      title: '2ème Prix',
      description: '50% de réduction sur "Vacances Créatives" et "Mémoire Fatale"',
      image: img1 
    },
    {
      title: '3ème Prix',
      description: 'Un tableau et le livre "Mémoire Fatale" de Marion de SOUZA',
      image: img3 
    },
  ];

function RewardsPage( props ) {
    // const {isLoading, setIsLoading} = useContext(ContextP);
    
    return (
        <div className='PriceViewContent'>
            <section className='section section_program'>
                <br/>
                <br/>
                <h3 className='h3'>
                    Nos Récompenses<span className='fa fa-check'></span>
                </h3>
                <div className='section_program_head'>
                    {/* <div className='section_program_head_left'>
                        <img src={programIllustrator} alt=''/>
                    </div> */}
                    <div className='section_program_head_title'>
                        
                        <h4>
                            Motivez-vous avec nos récompenses exceptionnelles pour les meilleures campagnes.
                        </h4>
                        
                    </div>
                </div>
            </section>
            <section className='programSection'>
                <Container>
                    <Grid container spacing={4}>
                        {rewards.map((reward, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card>
                            <CardMedia
                                component="img"
                                height="200"
                                image={reward.image}
                                alt={reward.title}
                                
                            />
                            <CardContent>
                                <Typography variant="h6">{reward.title}</Typography>
                                <Typography variant="body2" color="textSecondary">
                                {reward.description}
                                </Typography>
                            </CardContent>
                            </Card>
                        </Grid>
                        ))}
                    </Grid>
                </Container>
            </section>
        </div>
    );
}

export default RewardsPage;