import React, { useEffect, useContext }from 'react';

import '../styles/login.screen.style.scss';

/**Importation des images */
import background_video from '../assets/videos/183107-870151708_small.mp4';

import { ContextP } from "../context/provider";

function RegistrationView( props ) {
    const {addREGISTRATION} = useContext(ContextP);

    function _add_() {
        // let data = {
        //     "first_name" : document.forms["registrationForm"]["firstName"].value,
        //     "last_name" : document.forms["registrationForm"]["lastName"].value,
        //     "email" : document.forms["registrationForm"]["email"].value,
        //     "phone": document.forms["registrationForm"]["phoneNumber"].value,
        //     "bill" : Number(document.forms["registrationForm"]["bill"].value),
        // };
        let data = new FormData();
        data.append('email', document.forms["registrationForm"]["email"].value);
        data.append('phone', document.forms["registrationForm"]["phoneNumber"].value);
        data.append('first_name', document.forms["registrationForm"]["firstName"].value);
        data.append('last_name', document.forms["registrationForm"]["lastName"].value);
        data.append('bill', document.forms["registrationForm"]["bill"].value);
        addREGISTRATION(data, (res)=>{
            window.location.href = "https://me.fedapay.com/htEhPYR_";
        }, (e)=>{
            console.log(e);
        });
    }

    useEffect(() => {
        
    },[]);
    return (
        
        <div className='section_sc login'>
            <div className='login_sc_back'>
                {/* <video autoPlay muted loop id="myVideo">
                    <source src={background_video} type="video/mp4" />
                </video> */}
            </div>
            <div className='login_left'>
                <p>
                    Ne manquez pas cette opportunité unique de rejoindre notre Classroom et de devenir un pionnier de la publicité assistée par l'IA. Remplissez le formulaire ci-dessous pour vous inscrire.
                </p>
            </div>
            <div className='login_content'>
                <div className='login_content_head'>
                    <div className='login_content_head_title'>
                        
                    </div>
                </div>

                <form action='#' method='POST' className='login_content_form' name='registrationForm'
                    onSubmit={(e) => {
                        e.preventDefault();
                        _add_();
                    }}
                >
                    <div className='flex'>
                        <div className='login_content_form_input'>
                            <div className='login_content_form_input_title'>
                                <span className='fa fa-user'></span>
                                <span> Nom </span>
                            </div>
                            <div className='login_content_form_input_balise'>
                                <input name='lastName' required placeholder='' type='text'/>
                            </div>
                        </div>

                        <div className='login_content_form_input'>
                            <div className='login_content_form_input_title'>
                                <span className='fa fa-user'></span>
                                <span> Prénom </span>
                            </div>
                            <div className='login_content_form_input_balise'>
                                <input name='firstName' required placeholder='' type='text'/>
                            </div>
                        </div>
                    </div>
                    

                    <div className='flex'>
                        <div className='login_content_form_input'>
                            <div className='login_content_form_input_title'>
                                <span className='fa fa-envelope'></span>
                                <span> Email </span>
                            </div>
                            <div className='login_content_form_input_balise'>
                                <input name='email' required placeholder="" type='email'/>
                            </div>
                        </div>

                        <div className='login_content_form_input'>
                            <div className='login_content_form_input_title'>
                                <span className='fa fa-phone'></span>
                                <span> Téléphone </span>
                            </div>
                            <div className='login_content_form_input_balise'>
                                <input name='phoneNumber' required placeholder='' type='tel'/>
                            </div>
                        </div>
                    </div>

                    <div className='login_content_form_input'>
                        <div className='login_content_form_input_title'>
                            <span className='fa fa-money'></span>
                            <span> Montant </span>
                        </div>
                        <div className='login_content_form_input_balise'>
                            <input name='bill' required placeholder='' type='number'/>
                        </div>
                        <div className='login_content_form_input_subText'>
                            <span>Entre 3000 XOF et 5000 XOF</span>
                        </div>
                    </div>
                    <div className='login_content_form_button'>
                        <button>
                            Envoyer
                        </button>
                    </div>

                    {/* <div className='login_content_form_button returnHome'>
                        <a href='/'>
                            Retourner à l'accueil
                        </a>
                    </div> */}
                </form>
            </div>
        </div>
    );
}

export default RegistrationView;