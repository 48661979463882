import { createContext, useState } from "react"

import loader from '../assets/gif/loader.gif';

import RegistrationRequest from "../helpers/registration.requests.helper";
import AuthRequest from "../helpers/auth.requests.helper";
import ThemeRequest from "../helpers/theme.requests.helper";

import Alert from '@mui/material/Alert';

export const ContextP = createContext();

const REGISTRATION = new RegistrationRequest();
const AUTH = new AuthRequest();
const THEME = new ThemeRequest();


// début du composant
/**
 * Cette page sert de vue parente.
 * Tous les affichages globeaux, les fonctions globals seront deposer ici 
 * et heriter par toutes les autres pages
 */
export default function Provider({ children }) {
    /**state */
    const [isLoading, setIsLoading] = useState(false);
    const [ALertMessage, setAlertMessage] = useState(false);
    const [currentAlertMessage, setCurrentAlertMessage] = useState("");
    const [currentAlertType, setCurrentAlertType] = useState("warning");
    const [currentAlertAction, setCurrentAlertAction] = useState({action: ()=>{}});

    /**Functions */
    const addREGISTRATION = function (data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        REGISTRATION.add(data).then((response) => {
            // console.log(response.status);
            setIsLoading(false);
            if(response.status===201) {
                setCurrentAlertMessage("Inscription enregistrée avec succès");
                setCurrentAlertType("success");
                setAlertMessage(true);
                setCurrentAlertAction({action: ()=>{}});
                success(response.data);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const getREGISTRATIONS = function (success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        REGISTRATION.GetAll().then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response.data);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }


    const addTHEME = function (data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        THEME.add(data).then((response) => {
            // console.log(response.status);
            setIsLoading(false);
            if(response.status===201) {
                setCurrentAlertMessage("Theme ajouté avec succès");
                setCurrentAlertType("success");
                setAlertMessage(true);
                setCurrentAlertAction({action: ()=>{}});
                success(response.data);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const updateTHEME = function (id, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        THEME.update(data, id).then((response) => {
            // console.log(response.status);
            setIsLoading(false);
            if(response.status===201) {
               
                success(response.data);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const addTHEMEImage = function (data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        THEME.addImage(data).then((response) => {
            // console.log(response.status);
            setIsLoading(false);
            if(response.status===201) {
               
                success(response.data);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const DeleteTHEMEImage = function (id, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        THEME.deleteImage(id).then((response) => {
            // console.log(response.status);
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("Image supprimée avec succès");
                setCurrentAlertType("success");
                setAlertMessage(true);
                setCurrentAlertAction({action: ()=>{}});
                success(response.data);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const DeleteTHEME = function (id, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        THEME.delete(id).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("Theme supprimé avec succès");
                setCurrentAlertType("success");
                setAlertMessage(true);
                setCurrentAlertAction({action: ()=>{}});
                success(response.data);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const getTHEMES = function (success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        THEME.GetAll().then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response.data);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const LOGIN = function (data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        AUTH.login(data).then((response) => {
            // console.log(response.status);
            setIsLoading(false);
            if(response.status===200) {
                
                success(response.data);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const authREGISTRATION = function (data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        AUTH.registration(data).then((response) => {
            // console.log(response.status);
            setIsLoading(false);
            if(response.status===201) {
                setCurrentAlertMessage("Inscription enregistrée avec succès");
                setCurrentAlertType("success");
                setAlertMessage(true);
                setCurrentAlertAction({action: ()=>{}});
                success(response.data);
            }
            success(response.data);
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    return (
        <div>
            <ContextP.Provider 
                value={{
                    isLoading, 
                    setIsLoading, 

                    /**----------------------------- */
                    setAlertMessage,
                    setCurrentAlertMessage,
                    setCurrentAlertType,
                    setCurrentAlertAction,

                    /**___________________________________ */
                    addREGISTRATION,
                    getREGISTRATIONS,

                    addTHEME,
                    updateTHEME,
                    addTHEMEImage,
                    DeleteTHEMEImage,
                    DeleteTHEME,
                    getTHEMES,

                    authREGISTRATION,
                    LOGIN,
                    
                }}
            >
                {children}
                {
                    isLoading ?
                        <div>
                            <div className='globalALert_back'></div>
                                <div className='globalALert'>
                                    <div className='globalALert_content'>
                                        <div className='globalALert_content_loader'>
                                            <img src={loader} alt='freeJobs'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :
                            null
                }

                {
                    ALertMessage ?
                        <div>
                            <div className='globalALert_back'></div>
                            <div className='globalALert'>
                                <div className='globalALert_content'>
                                    <Alert style={{backgroundColor: "#ffffff"}} variant="outlined" elevation={6} severity={currentAlertType} onClose={() => {
                                        setAlertMessage(false);
                                        currentAlertAction.action();
                                    }}>{currentAlertMessage}</Alert>
                                </div>
                            </div>
                        </div>
                    :
                        null
                }
            </ContextP.Provider>
        </div>
    )
}